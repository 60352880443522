import React, { useState } from 'react'
import {
  useNotify,
  useRedirect,
  Edit,
  SimpleForm,
  FileInput,
  FileField,
  TextInput,
  required,
  getResources,
  SelectInput, FormTab
} from 'react-admin'
import axios from 'axios'
import { brands } from '../rules/RuleSelects'

const ProductUpload = (props) => {
  const notify = useNotify()
  const redirect = useRedirect()
  const save = data => {
    const formData = new FormData()
    formData.append('file', data.files.rawFile)
    formData.append('brand', data.brand)
    // Make an AJAX upload request using Axios (replace Cloudinary URL below with your own)
    return axios.post(process.env.REACT_APP_BASE_URL + 'upload', formData, {
      headers: { 'X-Requested-With': 'XMLHttpRequest' }
    }).then(response => {
      redirect('/product')
      notify(response.data, 'info')
    }).catch(error => {
      console.log(error)
    })
  }

  return (
    <SimpleForm save={(data) => {
      save(data)
    }}
    >
      <SelectInput
        label='Brand' source='brand' validate={required()} choices={brands}
      />
      <FileInput source='files' validate={required()} label='Product spreadsheet' accept='.xlsx,.csv'>
        <FileField source='src' title='title' />
      </FileInput>

    </SimpleForm>
  )
}

export default ProductUpload
