import React, { useState, useEffect, useCallback } from 'react'
import { useVersion, useDataProvider } from 'react-admin'
import { useMediaQuery } from '@material-ui/core'
import Welcome from './Welcome'

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '1em' },
  rightCol: { flex: 1, marginLeft: '1em' },
  singleCol: { marginTop: '2em', marginBottom: '2em' }
}

const Dashboard = () => {
  const [state, setState] = useState({})
  const version = useVersion()
  const dataProvider = useDataProvider()
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'))
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))

  useEffect(() => {
  }, [version]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Welcome />
    </div>
  )
}

export default Dashboard
