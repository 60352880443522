import React, {useEffect, useState} from 'react'
import {SimpleShowLayout} from 'react-admin'
import {makeStyles} from '@material-ui/core/styles'
import classnames from 'classnames'
import Paper from '@material-ui/core/Paper'
import CardHeader from '@material-ui/core/CardHeader'
import {Card, Button, Icon} from '@material-ui/core'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import Collapse from '@material-ui/core/Collapse'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import {red} from '@material-ui/core/colors'
import FavoriteIcon from '@material-ui/icons/Favorite'
import ShareIcon from '@material-ui/icons/Share'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'
import axios from 'axios'
import RuleForm from "./RuleForm";

class RuleContacts extends React.Component {
    constructor(props) {
        super(props);
        this.state.record = props.record
    }

    // State will apply to the posts object which is set to loading by default
    state = {
        count: 0,
        isLoading: true,
        errors: null
    };

    // Now we're going to make a request for data using axios
    async getCount(record) {
      console.log(record)
        await axios
            // This is where the data is hosted
            .post(process.env.REACT_APP_BASE_URL + 'blendcount', record.payload)
            // Once we get a response and store data, let's change the loading state
            .then(response => {
                console.log(response)
                this.setState({
                    count: response.data.count,
                    isLoading: false
                });
            })
            // If we catch any errors connecting, let's update accordingly
            .catch(error => {
              console.log(error)
                this.setState({error, isLoading: false})
            });

    }

    // Let's our app know we're ready to render the data
    async componentDidMount() {
     await this.getCount(this.state.record);
    }

    // Putting that data to use
    render() {
        const {isLoading, count} = this.state;
        return (
            <React.Fragment>
                {!isLoading ? <span>{count}</span> : (
                    <span>Loading...</span>
                )}

            </React.Fragment>
        );
    }
}

export default RuleContacts