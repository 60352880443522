import React from 'react'
import { List, Datagrid, TextField } from 'react-admin'

export const RuleList = props => (

  <List {...props}>
    <Datagrid rowClick='show'>
      <TextField source='payload.journeyName' label='Journey Name' />
    </Datagrid>
  </List>
)

export default RuleList
