import React from 'react'
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  NumberInput,
  UrlInput,
  ReferenceManyField,
  Datagrid,
  EditButton
} from 'react-admin'

const ProductCreate = props => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source='id' />
        <TextInput source='brand' label='Brand' />
        <TextInput source='market' label='Market' />
        <TextInput source='commodity' label='Commodity' />
        <TextInput source='country' label='Country' />
        <TextInput source='region' label='Region' />
        <TextInput source='name' label='Product Name' />
        <NumberInput source='rank' label='Rank' />
        <DateInput source='startwhen' label='Effective From' />
        <DateInput source='endwhen' label='Effective To' />
        <TextInput source='journey' label='Journey' />
        <TextInput source='url' label='Product URL' />
        <TextInput source='product' label='Product' />
        <TextInput source='hull' label='Hull Type' />
        <TextInput source='skippered' label='Skippered' />
        <TextInput source='discount' label='Discount' />
        <TextInput source='producttypecode' label='Product Type' />
        <TextInput source='productstylecode' label='Product Style' />
        <TextInput source='partytypecode' label='Party Type' />
        <TextInput source='partytypeflexible' label='Party Type Flexible' />
      </SimpleForm>
    </Create>
  )
}

export default ProductCreate
