import {
  Create, DeleteButton, ListButton, ShowButton, TopToolbar
} from 'react-admin'

import RuleForm from './RuleForm'
import React from 'react'
const RuleCreateActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
)
export class RuleCreateForm extends React.Component {
  render () {
    return (

      <Create actions={<RuleCreateActions />} {...this.props}>
        <RuleForm />
      </Create>
    )
  }
}

export default RuleCreateForm
