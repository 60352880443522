import React from 'react'
import { List, Filter, Edit, SimpleForm, TextInput, DateInput, ReferenceManyField, Datagrid, TextField, DateField, EditButton } from 'react-admin'

const HistoryFilter = (props) => (
  <Filter {...props} />
)

export const HistoryList = props => (
  <List filters={<HistoryFilter />} {...props}>
    <Datagrid rowClick='edit'>
      <TextField source='id' />
      <TextField source='brand' />
      <TextField source='catalystid' />
      <TextField source='blenddata' />
    </Datagrid>
  </List>
)

export default HistoryList
