import {
  SelectInput,
  TextInput,
  TabbedForm,
  FormTab,
  FormDataConsumer,
  BooleanInput,
  NumberInput,
  required,
  CheckboxGroupInput,
  RadioButtonGroupInput,
  ArrayInput,
  AutocompleteInput,
  SimpleFormIterator
} from 'react-admin'
import {
  DestinationSelectionOptions,
  productTypes,
  partyTypes,
  productStyles,
  inMarketSpecialSelection,
  overrideOptionsHayesJarvis,
  overrideOptionsSunsail,
  selectionMethods,
  days,
  daystype,
  brands,
  rulesbrands,
  contactProperties,
  runFrequencies,
  productSunsail,
  hulltype,
  skippered,
  HJregions,
  SSregions,
  SSbases,
  HJdests
} from './RuleSelects'
import React, { Component } from 'react'
import Cron from 'react-cron-generator'
import 'react-cron-generator/dist/cron-builder.css'

const Duration = ({ ...props }) => (
  <div>
    <SelectInput label='Before/After' validate={required()} source='payload.days' choices={days} />
    <SelectInput label='Date field' validate={required()} source='payload.daystype' choices={daystype} />
    <NumberInput label='Number of days' validate={required()} source='payload.number' step={1} />
  </div>
)
const InMarketAll = ({ ...props }) => (
  <div>
    <NumberInput label='In market all, likelihoold score >=' validate={required()} source='payload.inmarketallthreshhold' step={0.1} />
  </div>
)

const HubSpotOverrides = function ({ formData }) {
  const fields = []

  if (formData.hasOwnProperty('payload') && formData.payload.hasOwnProperty('pushunknowntohs')) {
    if (formData.payload.pushunknowntohs) {
      fields.push(<TextInput label='Unknown product code' source='payload.unknownproductcode' defaultValue='UNKNOWN' />)

      if (formData.payload.brand == 'HAYES_JARVIS') {
        fields.push(<TextInput label='Unknown product URL' source='payload.unknownproducturl' defaultValue='https://hayesandjarvis.co.uk' />)
      }
      if (formData.payload.brand == 'SUNSAIL') {
        fields.push(<TextInput label='Unknown product URL' source='payload.unknownproducturl' defaultValue='https://sunsail.com' />)
      }
    }
  }
  return fields
}

const InMarketSpecialFields = function ({ formData }) {
  const fields = []
  if (formData.payload.hasOwnProperty('inmarketspecial')) {
    if (formData.payload.inmarketspecial.includes('inmarketrepeat')) {
      fields.push(<NumberInput
        validate={required()} key='inmarketrepeat' label='In market repeats, likelihood score >= '
        source='payload.inmarketrepeatthreshhold'
        step={0.1}
                  />)
    }
    if (formData.payload.inmarketspecial.includes('inmarketprospects')) {
      fields.push(<NumberInput
        validate={required()} key='inmarketprospects' label='In market prospects, likelihood score >= '
        source='payload.inmarketprospectthreshhold'
        step={0.1}
                  />)
    }
  }
  return fields
}

const OverrideFields = function ({ formData, scopedFormData, getSource, record }) {
  console.log(scopedFormData)
  const fields = []
  if (formData.payload.brand == 'HAYES_JARVIS') {
    if (scopedFormData && scopedFormData.hasOwnProperty('brandoverrides')) {
      if (scopedFormData.brandoverrides.includes('useproductstyle')) {
        fields.push(<SelectInput
          choices={productStyles} label='Product style' validate={required()}
          source={getSource('productstyle')}
                    />)
      }
      if (scopedFormData.brandoverrides.includes('usepartytype')) {
        fields.push(<SelectInput
          choices={partyTypes} label='Party type' validate={required()}
          source={getSource('partytype')}
                    />)
      }
      if (scopedFormData.brandoverrides.includes('useproducttype')) {
        fields.push(<SelectInput
          choices={productTypes} label='Product type' validate={required()}
          source={getSource('producttype')}
                    />)
      }
    }
  }
  if (formData.payload.brand == 'SUNSAIL') {
    if (scopedFormData && scopedFormData.hasOwnProperty('brandoverrides')) {
      if (scopedFormData.brandoverrides.includes('useproductsunsail')) {
        fields.push(<SelectInput
          choices={productSunsail} label='Product' validate={required()}
          source={getSource('productsunsail')}
                    />)
      }
      console.log(scopedFormData)
      if (scopedFormData.brandoverrides.includes('usehulltype')) {
        fields.push(<SelectInput
          choices={hulltype} label='Hull type' validate={required()}
          source={getSource('hulltype')}
                    />)
      }
      if (scopedFormData.brandoverrides.includes('useskippered')) {
        fields.push(<SelectInput
          choices={skippered} label='Skippered' validate={required()}
          source={getSource('skippered')}
                    />)
      }
    }
  }

  return fields
}
const DestinationField = function ({ formData, scopedFormData, getSource, record }) {
  if (record && record.hasOwnProperty('destselection')) {
    switch (record.destselection) {
      case 'dest2':
        if (formData.payload.brand == 'HAYES_JARVIS') {
          // return <TextInput label='Static destination' validate={required()} source={getSource('staticdest')} />
          return <AutocompleteInput label='Static destination' validate={required()} source={getSource('staticdest')} choices={HJdests} />
        }
        if (formData.payload.brand == 'SUNSAIL') {
          return <SelectInput label='Static base' validate={required()} source={getSource('staticdest')} choices={SSbases} />
        }
      case 'dest4':
        return <NumberInput label='Destination/base' validate={required()} source={getSource('destnum')} />
      case 'dest5':
        return <NumberInput label='Linked destination' validate={required()} source={getSource('linkeddest')} />
      case 'dest6':
        if (formData.payload.brand == 'HAYES_JARVIS') {
          return <SelectInput label='Static region' validate={required()} source={getSource('staticregion')} choices={HJregions} />
        }
        if (formData.payload.brand == 'SUNSAIL') {
          return <SelectInput label='Static region' validate={required()} source={getSource('staticregion')} choices={SSregions} />
        }
      case 'dest7':
        return <NumberInput label='Region destination' validate={required()} source={getSource('regiondest')} />
      default:
        return null
    }
  }
  return null
}

const BrandOverrides = function ({ formData, scopedFormData, getSource, record }) {
  if (formData && formData.hasOwnProperty('payload')) {
    if (formData.payload.hasOwnProperty('brand')) {
      if (formData.payload.brand == 'SUNSAIL') {
        return <CheckboxGroupInput
          label='Overrides' source={getSource('brandoverrides')}
          choices={overrideOptionsSunsail}
               />
      }
      if (formData.payload.brand == 'HAYES_JARVIS') {
        return <CheckboxGroupInput
          label='Overrides' source={getSource('brandoverrides')}
          choices={overrideOptionsHayesJarvis}
               />
      }
    }
  }
  return []
}
const DestinationSelection = ({ ...props }) => (
  <div>
    <FormDataConsumer subscription={{ values: true }}>
      {({ getSource, scopedFormData }) => {
        return (
          <DestinationSelection />
        )
      }}
    </FormDataConsumer>

  </div>
)

const InMarketSpecial = ({ ...props }) => (
  <div>
    <CheckboxGroupInput
      label='' source='payload.inmarketspecial' choices={inMarketSpecialSelection}
    />
    <FormDataConsumer subscription={{ values: true }}>
      {({ formData }) =>
        <InMarketSpecialFields formData={formData} />}
    </FormDataConsumer>
  </div>
)

const SelectMethodFields = ({ formData }) => {
  const fields = []
  if (formData.hasOwnProperty('payload') && formData.payload.hasOwnProperty('selectMethod')) {
    if (formData.payload.selectMethod == 'duration') {
      fields.push(<Duration />)
    }

    if (formData.payload.selectMethod == 'inmarketall') {
      fields.push(<InMarketAll />)
    }

    if (formData.payload.selectMethod == 'inmarketspecial') {
      fields.push(<InMarketSpecial />)
    }
  }
  return fields
}

export class RuleForm extends React.Component {
  render () {
    return (
      <TabbedForm {...this.props} undoable={false} redirect='show'>
        <FormTab label='Journey data'>
          <TextInput disabled label='Id' source='id' />
          <TextInput label='Journey Name' source='payload.journeyName' validate={required()} />
          <SelectInput
            label='Brand' source='payload.brand' validate={required()} choices={rulesbrands}
          />
          <SelectInput
            label='Contact property' source='payload.contactProperty' validate={required()} choices={contactProperties}
          />
        </FormTab>
        <FormTab label='Contact selection'>
          <RadioButtonGroupInput
            source='payload.selectMethod' choices={selectionMethods}
            label='Contact selection method'
          />
          <FormDataConsumer subscription={{ values: true }}>
            {({ formData }) =>
              (<SelectMethodFields formData={formData} />)}
          </FormDataConsumer>

        </FormTab>
        <FormTab label='Run frequency'>
          <RadioButtonGroupInput label='Run Frequency' source='payload.runfreq' choices={runFrequencies} />
          <Cron
            onChange={(e) => { this.setState({ value: e }); console.log(e) }}
            showResultText
            showResultCron
          />
        </FormTab>
        <FormTab label='Rules'>
          <ArrayInput label='Rules' source='payload.rules'>
            <SimpleFormIterator>
              <NumberInput
                label='Products' helperText='Number of products to use from this rule set'
                validate={required()} source='products' step={1} defaultValue={0}
              />

              <RadioButtonGroupInput
                label='Destination selection'
                choices={DestinationSelectionOptions} source='destselection'
              />
              <FormDataConsumer>
                {({
                  formData, // The whole form data
                  scopedFormData, // The data for this item of the ArrayInput
                  getSource // A function to get the valid source inside an ArrayInput
                }) =>
                  <DestinationField
                    source={getSource('destselection')} record={scopedFormData}
                    getSource={getSource} formData={formData}
                    scopedFormData={scopedFormData}
                  />}
              </FormDataConsumer>

              <FormDataConsumer>
                {({
                  formData, // The whole form data
                  scopedFormData, // The data for this item of the ArrayInput
                  getSource // A function to get the valid source inside an ArrayInput
                }) =>
                  <BrandOverrides
                    source={getSource('brandoverrides')} record={scopedFormData}
                    getSource={getSource} formData={formData}
                    scopedFormData={scopedFormData}
                  />}
              </FormDataConsumer>

              <FormDataConsumer>
                {({
                  formData, // The whole form data
                  scopedFormData, // The data for this item of the ArrayInput
                  getSource // A function to get the valid source inside an ArrayInput
                }) =>
                  <OverrideFields
                    source={getSource('overrides')} record={scopedFormData}
                    getSource={getSource} formData={formData}
                    scopedFormData={scopedFormData}
                  />}
              </FormDataConsumer>
              <NumberInput
                label='Offset' helperText='' source='offset'
                step={1} defaultValue={0}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label='Misc'>
          <BooleanInput
            label='Use properties (style, type, party, hull, skippered, etc) from other campaigns (if available) to back-fill?'
            source='payload.backfill'
          />
          <BooleanInput
            label='Push to Hubspot even if product is unknown?'
            source='payload.pushunknowntohs'
          />
          <FormDataConsumer subscription={{ values: true }}>
            {({ formData }) =>
              <HubSpotOverrides formData={formData} />}
          </FormDataConsumer>
          <BooleanInput
            label='Allow regional match if no destination/base level match?'
            source='payload.regionalmatch'
          />
          <TextInput label='Re-enter journey days' source='payload.reenterjourneydays' />
          <TextInput
            label='Re-enter same destination/base journey days'
            source='payload.reenterjourneydayssamedest'
          />
        </FormTab>
      </TabbedForm>
    )
  }
}

export default RuleForm
