// in App.js
import React, { Component } from 'react'
import buildGraphQLProvider from 'ra-data-graphql-simple'
import { Admin, Resource } from 'react-admin'
import customRoutes from './routes'
import products from './products'
import rules from './rules'
import history from './history'
import { Login, Layout } from './layout'
import authProvider from './authProvider'
import { Dashboard } from './dashboard'
import { Authenticator } from 'aws-amplify-react'
import { withAuthenticator } from 'aws-amplify-react' // or 'aws-amplify-react-native';
import Amplify from 'aws-amplify'
import url from 'url'

const awsmobile = {
  aws_project_region: 'eu-west-1',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_5Pw0BHuVo',
  aws_user_pools_web_client_id: '6l2t5h8b778m50q3a47b5vj4af',
  redirect_uri: process.env.REACT_APP_FE_URL,
  oauth: {
    domain: 'thl-cognito-private.idm.travelopia.cloud',
    scope: [
      'email',
      'openid',
      'https://blenderengine.coredev.travelopia.cloud/global.admin',
      'https://blenderengine.coredev.travelopia.cloud/global.user',
      'https://blenderengine.coredev.travelopia.cloud/moorings.admin',
      'https://blenderengine.coredev.travelopia.cloud/moorings.user',
      'https://blenderengine.coredev.travelopia.cloud/sunsail.admin',
      'https://blenderengine.coredev.travelopia.cloud/sunsail.user'
    ],
    redirect_uri: process.env.REACT_APP_FE_URL,
    redirectSignIn: process.env.REACT_APP_FE_URL,
    responseType: 'token'
  }
}

Amplify.configure(awsmobile)

class App extends Component {
  constructor () {
    super()
    this.state = { dataProvider: null }
  }

  componentDidMount () {
    const base_url = url.resolve(process.env.REACT_APP_BASE_URL, 'graphql')
    buildGraphQLProvider({ clientOptions: { uri: base_url } })
      .then(dataProvider => this.setState({ dataProvider }))
  }

  render () {
    const { dataProvider } = this.state
    if (!dataProvider) {
      return <div>Loading</div>
    }
    return (
      <Admin
        title='Blender 2.0'
        layout={Layout}
        customRoutes={customRoutes}
        dataProvider={dataProvider}
        authProvider={authProvider}
        dashboard={Dashboard}
      >
        <Resource name='Product' {...products} />
        <Resource name='Rule' {...rules} />
        <Resource name='History' {...history} />
      </Admin>
    )
  }
}

export default withAuthenticator(App)
