import ProductIcon from '@material-ui/icons/Collections'
import ProductEdit from './ProductEdit'
import ProductCreate from './ProductCreate'
import ProductList from './ProductList'
import ProductShow from './ProductShow'

export default {
  create: ProductCreate,
  edit: ProductEdit,
  icon: ProductIcon,
  list: ProductList,
  show: ProductShow
}
