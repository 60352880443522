import {
  Edit, ShowButton, ListButton, TopToolbar
} from 'react-admin'

import React from 'react'
import RuleForm from './RuleForm'

const RuleEditActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ShowButton label='View' basePath={basePath} record={data} />
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
)

const RuleTitle = ({ record }) => {
  return <span>Journey {record ? `${record.payload.journeyName}` : ''}</span>
}

export class RuleEditForm extends React.Component {
  render () {
    return (
      <Edit undoable={false} title={<RuleTitle />} actions={<RuleEditActions />} {...this.props}>
        <RuleForm />
      </Edit>
    )
  }
}

export default RuleEditForm
