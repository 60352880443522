import React from 'react'
import {
  Show,
  SimpleShowLayout,
  List,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceManyField,
  Datagrid,
  TextField,
  Button,
  DateField,
  EditButton
} from 'react-admin'

export const HistoryShow = props => (
  <Show {...props}>
    <SimpleShowLayout />
  </Show>

)

export default HistoryShow
