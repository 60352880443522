import React from 'react'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import HomeIcon from '@material-ui/icons/Home'
import CodeIcon from '@material-ui/icons/Code'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslate } from 'react-admin'

const useStyles = makeStyles({
  media: {
    height: '18em'
  }
})

const mediaUrl = `https://marmelab.com/posters/beard-${parseInt(
    Math.random() * 10,
    10
) + 1}.jpeg`

const Welcome = () => {
  const translate = useTranslate()
  const classes = useStyles()
  return (
    <Card>
      <CardContent>
        <Typography variant='h5' component='h2'>
         Welcome to Blender v2.0
        </Typography>
        <Typography component='p'>
          In development
        </Typography>
      </CardContent>
      <CardActions style={{ justifyContent: 'flex-end' }}>
        <Button href='https://www.travelopia.com'>
          <HomeIcon style={{ paddingRight: '0.5em' }} />
         www.travelopia.com
        </Button>
      </CardActions>
    </Card>
  )
}

export default Welcome
