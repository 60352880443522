// in src/Menu.js
import React, { createElement, useState } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from '@material-ui/core'
import { MenuItemLink, getResources } from 'react-admin'
import MenuItemLinkExternal from './MenuItemLinkExternal'
import { withRouter } from 'react-router-dom'
import JourneyIcon from '@material-ui/icons/Map'
import TocIcon from '@material-ui/icons/Toc'
import AssessmentIcon from '@material-ui/icons/Assessment'
import ProductIcon from '@material-ui/icons/Storefront'
import RulesIcon from '@material-ui/icons/Functions'
import Camera from '@material-ui/icons/Camera'
import ImageAspectRatioIcon from '@material-ui/icons/ImageAspectRatio'
import BrokenImageIcon from '@material-ui/icons/BrokenImage'
import BurstModeIcon from '@material-ui/icons/BurstMode'
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary'
import ImageSearchIcon from '@material-ui/icons/ImageSearch'
import Filter9PlusIcon from '@material-ui/icons/Filter9Plus'

import SubMenu from './SubMenu'

const Menu = ({ onMenuClick, dense, logout }) => {
  const [state, setState] = useState({
    menuBlender: true,
    menuProducts: true,
    menuBic: true,
    menuBic2: true
  })
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'))
  const open = useSelector(state => state.admin.ui.sidebarOpen)
  const resources = useSelector(getResources)
  const handleToggle = menu => {
    setState(state => ({ ...state, [menu]: !state[menu] }))
  }

  return (
    <div>
      <SubMenu
        handleToggle={() => handleToggle('menuProducts')}
        isOpen={state.menuProducts}
        sidebarIsOpen={open}
        name='Products'
        icon={<ProductIcon />}
        dense={dense}
      >
        <MenuItemLink
          to='/product'
          primaryText='Listing'
          leftIcon={<TocIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to='/upload-products'
          primaryText='Upload spreadsheet'
          leftIcon={<AssessmentIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>

      <SubMenu
        handleToggle={() => handleToggle('menuBlender')}
        isOpen={state.menuBlender}
        sidebarIsOpen={open}
        name='Blender'
        icon={<Filter9PlusIcon />}
        dense={dense}
      >
        <MenuItemLink
          key='Rule'
          to='/rule'
          primaryText='Rules'
          leftIcon={<RulesIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
        <MenuItemLink
          to='/Rule/create'
          primaryText='Create journey'
          leftIcon={<JourneyIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
        <MenuItemLink
          key='History'
          to='/history'
          primaryText='History'
          leftIcon={<RulesIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      </SubMenu>

      <SubMenu
        handleToggle={() => handleToggle('menuBic')}
        isOpen={state.menuBic}
        sidebarIsOpen={open}
        name='BIC'
        icon={<Camera />}
        dense={dense}
      >

        <SubMenu
          handleToggle={() => handleToggle('menuBicHJ')}
          isOpen={state.menuBicHJ}
          sidebarIsOpen={open}
          name='Hayes &amp; Harvis'
          icon={<Camera />}
          dense={dense}
        >

          <SubMenu
            handleToggle={() => handleToggle('menuBicHJMO')}
            isOpen={state.menuBicHJMO}
            sidebarIsOpen={open}
            name='MO'
            icon={<Camera />}
            dense={dense}
          >
            <MenuItemLinkExternal
              href='https://bic.coredev.travelopia.cloud/ver?imbauth=45646453341223445QQzzzzzzz0012Aa'
              to='https://bic.coredev.travelopia.cloud/ver?imbauth=45646453341223445QQzzzzzzz0012Aa'
              component='a'
              primaryText='Version'
              disabled
              leftIcon={<ImageAspectRatioIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
            <MenuItemLinkExternal
              href='https://bic.coredev.travelopia.cloud/clearSSMO?imbauth=45646453341223445QQzzzzzzz0012Aa'
              to='https://bic.coredev.travelopia.cloud/clearSSMO?imbauth=45646453341223445QQzzzzzzz0012Aa'
              component='a'
              primaryText='Clear'
              disabled
              leftIcon={<BrokenImageIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
            <MenuItemLinkExternal
              href='https://bic.coredev.travelopia.cloud/list?imbauth=45646453341223445QQzzzzzzz0012Aa&brand=SS'
              to='https://bic.coredev.travelopia.cloud/list?imbauth=45646453341223445QQzzzzzzz0012Aa&brand=SS'
              component='a'
              primaryText='List'
              disabled
              leftIcon={<BurstModeIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
            <MenuItemLinkExternal
              href='https://bic.coredev.travelopia.cloud/imagedump?imbauth=45646453341223445QQzzzzzzz0012Aa&brand=SS'
              to='https://bic.coredev.travelopia.cloud/imagedump?imbauth=45646453341223445QQzzzzzzz0012Aa&brand=SS'
              component='a'
              primaryText='Image dump'
              disabled
              leftIcon={<ImageSearchIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
            <MenuItemLinkExternal
              href='https://bic.coredev.travelopia.cloud/loadSSMO?imbauth=45646453341223445QQzzzzzzz0012Aa'
              to='https://bic.coredev.travelopia.cloud/loadSSMO?imbauth=45646453341223445QQzzzzzzz0012Aa'
              component='a'
              primaryText='Load'
              disabled
              leftIcon={<PhotoLibraryIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          </SubMenu>

          <SubMenu
            handleToggle={() => handleToggle('menuBicHJQM')}
            isOpen={state.menuBicHJQM}
            sidebarIsOpen={open}
            name='QM'
            icon={<Camera />}
            dense={dense}
          >
            <MenuItemLinkExternal
              href='https://bic.coredev.travelopia.cloud/ver?imbauth=45646453341223445QQzzzzzzz0012Aa'
              to='https://bic.coredev.travelopia.cloud/ver?imbauth=45646453341223445QQzzzzzzz0012Aa'
              component='a'
              primaryText='Version'
              disabled
              leftIcon={<ImageAspectRatioIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
            <MenuItemLinkExternal
              href='https://bic.coredev.travelopia.cloud/clearSSMO?imbauth=45646453341223445QQzzzzzzz0012Aa'
              to='https://bic.coredev.travelopia.cloud/clearSSMO?imbauth=45646453341223445QQzzzzzzz0012Aa'
              component='a'
              primaryText='Clear'
              disabled
              leftIcon={<BrokenImageIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
            <MenuItemLinkExternal
              href='https://bic.coredev.travelopia.cloud/list?imbauth=45646453341223445QQzzzzzzz0012Aa&brand=SS'
              to='https://bic.coredev.travelopia.cloud/list?imbauth=45646453341223445QQzzzzzzz0012Aa&brand=SS'
              component='a'
              primaryText='List'
              disabled
              leftIcon={<BurstModeIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
            <MenuItemLinkExternal
              href='https://bic.coredev.travelopia.cloud/imagedump?imbauth=45646453341223445QQzzzzzzz0012Aa&brand=SS'
              to='https://bic.coredev.travelopia.cloud/imagedump?imbauth=45646453341223445QQzzzzzzz0012Aa&brand=SS'
              component='a'
              primaryText='Image dump'
              disabled
              leftIcon={<ImageSearchIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
            <MenuItemLinkExternal
              href='https://bic.coredev.travelopia.cloud/loadSSMO?imbauth=45646453341223445QQzzzzzzz0012Aa'
              to='https://bic.coredev.travelopia.cloud/loadSSMO?imbauth=45646453341223445QQzzzzzzz0012Aa'
              component='a'
              primaryText='Load'
              disabled
              leftIcon={<PhotoLibraryIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          </SubMenu>

        </SubMenu>

        <SubMenu
          handleToggle={() => handleToggle('menuBicSS')}
          isOpen={state.menuBicSS}
          sidebarIsOpen={open}
          name='Sunsail'
          icon={<Camera />}
          dense={dense}
        >

          <SubMenu
            handleToggle={() => handleToggle('menuBicSSMO')}
            isOpen={state.menuBicSSMO}
            sidebarIsOpen={open}
            name='MO'
            icon={<Camera />}
            dense={dense}
          >
            <MenuItemLinkExternal
              href='https://bic.coredev.travelopia.cloud/ver?imbauth=45646453341223445QQzzzzzzz0012Aa'
              to='https://bic.coredev.travelopia.cloud/ver?imbauth=45646453341223445QQzzzzzzz0012Aa'
              component='a'
              primaryText='Version'
              leftIcon={<ImageAspectRatioIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
            <MenuItemLinkExternal
              href='https://bic.coredev.travelopia.cloud/clearSSMO?imbauth=45646453341223445QQzzzzzzz0012Aa'
              to='https://bic.coredev.travelopia.cloud/clearSSMO?imbauth=45646453341223445QQzzzzzzz0012Aa'
              component='a'
              primaryText='Clear'
              leftIcon={<BrokenImageIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
            <MenuItemLinkExternal
              href='https://bic.coredev.travelopia.cloud/list?imbauth=45646453341223445QQzzzzzzz0012Aa&brand=SS'
              to='https://bic.coredev.travelopia.cloud/list?imbauth=45646453341223445QQzzzzzzz0012Aa&brand=SS'
              component='a'
              primaryText='List'
              leftIcon={<BurstModeIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
            <MenuItemLinkExternal
              href='https://bic.coredev.travelopia.cloud/imagedump?imbauth=45646453341223445QQzzzzzzz0012Aa&brand=SS'
              to='https://bic.coredev.travelopia.cloud/imagedump?imbauth=45646453341223445QQzzzzzzz0012Aa&brand=SS'
              component='a'
              primaryText='Image dump'
              leftIcon={<ImageSearchIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
            <MenuItemLinkExternal
              href='https://bic.coredev.travelopia.cloud/loadSSMO?imbauth=45646453341223445QQzzzzzzz0012Aa'
              to='https://bic.coredev.travelopia.cloud/loadSSMO?imbauth=45646453341223445QQzzzzzzz0012Aa'
              component='a'
              primaryText='Load'
              leftIcon={<PhotoLibraryIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          </SubMenu>

          <SubMenu
            handleToggle={() => handleToggle('menuBicSSQM')}
            isOpen={state.menuBicSSQM}
            sidebarIsOpen={open}
            name='QM'
            icon={<Camera />}
            dense={dense}
          >
            <MenuItemLinkExternal
              href='https://bic.coredev.travelopia.cloud/ver?imbauth=45646453341223445QQzzzzzzz0012Aa'
              to='https://bic.coredev.travelopia.cloud/ver?imbauth=45646453341223445QQzzzzzzz0012Aa'
              component='a'
              primaryText='Version'
              disabled
              leftIcon={<ImageAspectRatioIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
            <MenuItemLinkExternal
              href='https://bic.coredev.travelopia.cloud/clearSSMO?imbauth=45646453341223445QQzzzzzzz0012Aa'
              to='https://bic.coredev.travelopia.cloud/clearSSMO?imbauth=45646453341223445QQzzzzzzz0012Aa'
              component='a'
              primaryText='Clear'
              disabled
              leftIcon={<BrokenImageIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
            <MenuItemLinkExternal
              href='https://bic.coredev.travelopia.cloud/list?imbauth=45646453341223445QQzzzzzzz0012Aa&brand=SS'
              to='https://bic.coredev.travelopia.cloud/list?imbauth=45646453341223445QQzzzzzzz0012Aa&brand=SS'
              component='a'
              primaryText='List'
              disabled
              leftIcon={<BurstModeIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
            <MenuItemLinkExternal
              href='https://bic.coredev.travelopia.cloud/imagedump?imbauth=45646453341223445QQzzzzzzz0012Aa&brand=SS'
              to='https://bic.coredev.travelopia.cloud/imagedump?imbauth=45646453341223445QQzzzzzzz0012Aa&brand=SS'
              component='a'
              primaryText='Image dump'
              disabled
              leftIcon={<ImageSearchIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
            <MenuItemLinkExternal
              href='https://bic.coredev.travelopia.cloud/loadSSMO?imbauth=45646453341223445QQzzzzzzz0012Aa'
              to='https://bic.coredev.travelopia.cloud/loadSSMO?imbauth=45646453341223445QQzzzzzzz0012Aa'
              component='a'
              primaryText='Load'
              disabled
              leftIcon={<PhotoLibraryIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          </SubMenu>

        </SubMenu>

        <SubMenu
          handleToggle={() => handleToggle('menuBicMO')}
          isOpen={state.menuBicMO}
          sidebarIsOpen={open}
          name='Moorings'
          icon={<Camera />}
          dense={dense}
        >

          <SubMenu
            handleToggle={() => handleToggle('menuBicMOMO')}
            isOpen={state.menuBicMOMO}
            sidebarIsOpen={open}
            name='MO'
            icon={<Camera />}
            dense={dense}
          >
            <MenuItemLinkExternal
              href='https://bic.coredev.travelopia.cloud/ver?imbauth=45646453341223445QQzzzzzzz0012Aa'
              to='https://bic.coredev.travelopia.cloud/ver?imbauth=45646453341223445QQzzzzzzz0012Aa'
              component='a'
              primaryText='Version'
              disabled
              leftIcon={<ImageAspectRatioIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
            <MenuItemLinkExternal
              href='https://bic.coredev.travelopia.cloud/clearSSMO?imbauth=45646453341223445QQzzzzzzz0012Aa'
              to='https://bic.coredev.travelopia.cloud/clearSSMO?imbauth=45646453341223445QQzzzzzzz0012Aa'
              component='a'
              primaryText='Clear'
              disabled
              leftIcon={<BrokenImageIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
            <MenuItemLinkExternal
              href='https://bic.coredev.travelopia.cloud/list?imbauth=45646453341223445QQzzzzzzz0012Aa&brand=SS'
              to='https://bic.coredev.travelopia.cloud/list?imbauth=45646453341223445QQzzzzzzz0012Aa&brand=SS'
              component='a'
              primaryText='List'
              disabled
              leftIcon={<BurstModeIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
            <MenuItemLinkExternal
              href='https://bic.coredev.travelopia.cloud/imagedump?imbauth=45646453341223445QQzzzzzzz0012Aa&brand=SS'
              to='https://bic.coredev.travelopia.cloud/imagedump?imbauth=45646453341223445QQzzzzzzz0012Aa&brand=SS'
              component='a'
              primaryText='Image dump'
              disabled
              leftIcon={<ImageSearchIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
            <MenuItemLinkExternal
              href='https://bic.coredev.travelopia.cloud/loadSSMO?imbauth=45646453341223445QQzzzzzzz0012Aa'
              to='https://bic.coredev.travelopia.cloud/loadSSMO?imbauth=45646453341223445QQzzzzzzz0012Aa'
              component='a'
              primaryText='Load'
              disabled
              leftIcon={<PhotoLibraryIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          </SubMenu>

          <SubMenu
            handleToggle={() => handleToggle('menuBicMOQM')}
            isOpen={state.menuBicMOQM}
            sidebarIsOpen={open}
            name='QM'
            icon={<Camera />}
            dense={dense}
          >
            <MenuItemLinkExternal
              href='https://bic.coredev.travelopia.cloud/ver?imbauth=45646453341223445QQzzzzzzz0012Aa'
              to='https://bic.coredev.travelopia.cloud/ver?imbauth=45646453341223445QQzzzzzzz0012Aa'
              component='a'
              primaryText='Version'
              disabled
              leftIcon={<ImageAspectRatioIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
            <MenuItemLinkExternal
              href='https://bic.coredev.travelopia.cloud/clearSSMO?imbauth=45646453341223445QQzzzzzzz0012Aa'
              to='https://bic.coredev.travelopia.cloud/clearSSMO?imbauth=45646453341223445QQzzzzzzz0012Aa'
              component='a'
              primaryText='Clear'
              disabled
              leftIcon={<BrokenImageIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
            <MenuItemLinkExternal
              href='https://bic.coredev.travelopia.cloud/list?imbauth=45646453341223445QQzzzzzzz0012Aa&brand=SS'
              to='https://bic.coredev.travelopia.cloud/list?imbauth=45646453341223445QQzzzzzzz0012Aa&brand=SS'
              component='a'
              primaryText='List'
              disabled
              leftIcon={<BurstModeIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
            <MenuItemLinkExternal
              href='https://bic.coredev.travelopia.cloud/imagedump?imbauth=45646453341223445QQzzzzzzz0012Aa&brand=SS'
              to='https://bic.coredev.travelopia.cloud/imagedump?imbauth=45646453341223445QQzzzzzzz0012Aa&brand=SS'
              component='a'
              primaryText='Image dump'
              disabled
              leftIcon={<ImageSearchIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
            <MenuItemLinkExternal
              href='https://bic.coredev.travelopia.cloud/loadSSMO?imbauth=45646453341223445QQzzzzzzz0012Aa'
              to='https://bic.coredev.travelopia.cloud/loadSSMO?imbauth=45646453341223445QQzzzzzzz0012Aa'
              component='a'
              primaryText='Load'
              disabled
              leftIcon={<PhotoLibraryIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          </SubMenu>

        </SubMenu>

      </SubMenu>

      {isXSmall && logout}
    </div>
  )
}

export default withRouter(Menu)
