import React, { useEffect, useState } from 'react'
import { Toolbar, DeleteButton, SaveButton, SimpleForm, BooleanInput } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import Paper from '@material-ui/core/Paper'
import CardHeader from '@material-ui/core/CardHeader'
import { Card, Button, Icon } from '@material-ui/core'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import Collapse from '@material-ui/core/Collapse'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { red } from '@material-ui/core/colors'
import FavoriteIcon from '@material-ui/icons/Favorite'
import ShareIcon from '@material-ui/icons/Share'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'
import RuleContacts from './RuleContacts'
import axios from 'axios'

const useStyles = makeStyles({
  container: { minWidth: '30em', marginLeft: '1em', height: '100%' }
})

const RuleTitle = ({ record }) => {
  return <span>Blend {record ? `${record.payload.journeyName}` : ''}</span>
}

const RuleAside = ({ record }) => {
  const [blend, setBlend] = useState()
  const approve = function (record, hubspot) {
    record.payload.sendtohubspot = hubspot
    record.payload.id = record.id
    return axios.post(process.env.REACT_APP_BASE_URL + 'blend', record.payload).then(response => {
      setBlend(response.data)
    }).catch(error => {
    })
  }
  const classes = useStyles()
  return (
    <div>
      {record && (
        <Paper className={classes.container} elevation={1}>
          <CardHeader subheader={<RuleTitle record={record} />} />
          <CardActionArea>
            <CardContent>
              <Typography variant='body2' color='textSecondary' component='p'>
                                Current will blend <RuleContacts record={record} /> contacts
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions disableSpacing>
            <SimpleForm toolbar={null}>
              <div>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={(data) => {
                    approve(record, false)
                  }}

                  className={classes.button}
                  startIcon={<AddShoppingCartIcon />}
                >
                                Run blend sample
                </Button>
              </div>
              <div>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={(data) => {
                    approve(record, true)
                  }}

                  className={classes.button}
                  startIcon={<AddShoppingCartIcon />}
                >
                Run blend to hubspot
                </Button>
              </div>
            </SimpleForm>
          </CardActions>
          <pre>{JSON.stringify(blend, 0, 2)}</pre>
        </Paper>
      )}
    </div>

  )
}

export default RuleAside
