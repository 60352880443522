// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
// userlogin707cd80f_userpool_707cd80f-local
// const awsmobile = {
//     "aws_project_region": "eu-west-1",
//     "aws_cognito_identity_pool_id": "eu-west-1:ead1795e-7413-453b-835b-5ee70caf5655",
//     "aws_cognito_region": "eu-west-1",
//     "aws_user_pools_id": "eu-west-1_U7kxUQI5n",
//     "aws_user_pools_web_client_id": "1ard5bvsrfgnh90me3f47t6d2t",
//     "oauth": {}
// };

// Travelopia.IDM.Private

const awsmobile = {
  aws_project_region: 'eu-west-1',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_5Pw0BHuVo',
  aws_user_pools_web_client_id: '6l2t5h8b778m50q3a47b5vj4af',
  oauth: {
    domain: 'thl-cognito-private.idm.travelopia.cloud',
    scope: [
      'email',
      'openid',
      'https://blenderengine.coredev.travelopia.cloud/global.admin',
      'https://blenderengine.coredev.travelopia.cloud/global.user',
      'https://blenderengine.coredev.travelopia.cloud/moorings.admin',
      'https://blenderengine.coredev.travelopia.cloud/moorings.user',
      'https://blenderengine.coredev.travelopia.cloud/sunsail.admin',
      'https://blenderengine.coredev.travelopia.cloud/sunsail.user'
    ],
    redirectSignIn: 'https://blender.coredev.travelopia.cloud/',
    responseType: 'token'
  }
}
export default awsmobile
