// Authenticatd by default

import Amplify, { Auth } from 'aws-amplify'
import aws_exports from './aws-exports'

Amplify.configure(aws_exports)
export default {
  login: async ({ username, password }) => {
    return Promise.resolve()
  },
  logout: async () => {
    await Auth.signOut()
    return Promise.resolve()
  },
  checkAuth: async () => {
    return Promise.resolve()
  },
  getPermissions: () => {
    return Promise.resolve()
  }
}
//
// export default {
//     login: ({username, password}) => Auth.signIn(username, password),
//     logout: Auth.signOut,
//     checkError: ({status}) => {
//         return status === 401 || status === 403
//             ? Promise.reject()
//             : Promise.resolve()
//     },
//     checkAuth: () => localStorage.getItem('correct')
//         ? Promise.resolve()
//         : Promise.reject(),
//     getPermissions: () => {
//         const role = localStorage.getItem('role')
//         return Promise.resolve(role)
//     }
// }
