import { required, SelectInput } from 'react-admin'
import React from 'react'

export const selectionMethods = [
  { id: 'duration', name: 'Anniversary based' },
  { id: 'inmarketspecial', name: 'In market special' },
  { id: 'inmarketall', name: 'In market all' },
  { id: 'all', name: 'All contacts' }
]

export const days = [
  { id: 'before', name: 'Before' },
  { id: 'after', name: 'After' }
]
export const daystype = [
  // { id: 'bookinganniversary', name: 'Booking anniversery' },
  // { id: 'returnddate', name: 'Return date' },
  { id: 'pbBookingsBookingDate', name: 'pb Bookings Booking Date' },
  { id: 'bhBookingsFirstBookedDate', name: 'bh Bookings First Booked Date' },
  { id: 'bbLastBrochureRequestedDate', name: 'bb Last Brochure Requested Date' },
  { id: 'bhQuoteLastDate', name: 'bh Quote Last Date' },
  { id: 'pbBookingsReturnDate', name: 'pb Bookings Return Date' },
  { id: 'pbBookingsDepartureDate', name: 'pb Bookings Departure Date' },
  { id: 'paTimeSinceLastEnquiryDate', name: 'pa Time Since Last Enquiry Date' }
]
export const contactProperties = [
  { id: 'QM', name: 'QM (in-market)' },
  { id: 'MO', name: 'MO (monthly offers)' },
  { id: 'CJ', name: 'CJ (Commercial Journey)' }
]

export const brands = [
  { id: 'ALL', name: 'All' },
  { id: 'HAYES_JARVIS', name: 'Hayes & Jarvis' },
  { id: 'SUNSAIL', name: 'Sunsail' },
  { id: 'MOORINGS', name: 'Moorings' }
]

export const rulesbrands = [
  { id: 'HAYES_JARVIS', name: 'Hayes & Jarvis' },
  { id: 'SUNSAIL', name: 'Sunsail' },
  { id: 'MOORINGS', name: 'Moorings' }
]

export const productSunsail = [
  { id: 'contact', name: 'Contact Record' },
  { id: 'flotillla', name: 'Flotillla' },
  { id: 'city', name: 'City' },
  { id: 'school', name: 'School' },
  { id: 'cabin', name: 'Cabin' }
]

export const hulltype = [
  { id: 'contact', name: 'Contact Record' },
  { id: 'catamaran', name: 'Catamaran' },
  { id: 'monohull', name: 'Monohull' }
]

export const skippered = [
  { id: 'contact', name: 'Contact Record' },
  { id: 'yes', name: 'Yes' },
  { id: 'no', name: 'No' }
]

export const runFrequencies = [
  { id: 'daily', name: 'Daily' },
  { id: 'weekly', name: 'Weekly' },
  { id: 'monthly', name: 'Monthly' },
  { id: 'adhoc', name: 'Adhoc' }
]
export const overrideOptionsSunsail = [
  { id: 'useproductsunsail', name: 'Use \'Product\' (Flotilla, Bareboat, School, Cabin) from' },
  { id: 'usehulltype', name: 'Use \'Hull\' (Catamaran, Monohull) from' },
  { id: 'useskippered', name: 'Use \'Skippered\' (yes, no) from' }
]

export const overrideOptionsHayesJarvis = [
  { id: 'useproductstyle', name: 'Use \'Product Style\' (beach, city, safari) from' },
  { id: 'usepartytype', name: 'Use \'Party Type\' (family, couple, solo) from' },
  { id: 'useproducttype', name: 'Use \'Product Type\' (multi-centre, single-centre, tour) from' }
]

export const inMarketSpecialSelection = [
  { id: 'inmarketrepeat', name: 'In market repeats' },
  { id: 'inmarketprospects', name: 'In market prospects' }
]

export const productStyles = [
  { id: 'contact', name: 'Contact Record' },
  { id: 'beach', name: 'Beach' },
  { id: 'city', name: 'City' },
  { id: 'safari', name: 'Safari' }
]

export const partyTypes = [
  { id: 'contact', name: 'Contact record' },
  { id: 'family', name: 'Family' },
  { id: 'couple', name: 'Couple' },
  { id: 'solo', name: 'Solo' }
]

export const productTypes = [
  { id: 'contact', name: 'Contact record' },
  { id: 'MC', name: 'Multi centre' },
  { id: 'SC', name: 'Single centre' },
  { id: 'TO', name: 'Tour' }
]

export const DestinationSelectionOptions = [
  { id: 'dest1', name: 'One product for each recommended destination/base' },
  { id: 'dest2', name: 'All products based on a static destination/base' },
  { id: 'dest3', name: 'All products without using any destination/base' },
  { id: 'dest4', name: 'All products based on single recommended Destination/base' },
  { id: 'dest5', name: 'All products based on the Linked destination/base of a single recommendation' },
  { id: 'dest6', name: 'All products based on a single static region' },
  { id: 'dest7', name: 'All products based on the region of a single recommended destination/base' }
]

export const SSregions = [
  { id: 'North America', name: 'North America' },
  { id: 'Southeast Asia', name: 'Southeast Asia' },
  { id: 'South Pacific', name: 'South Pacific' },
  { id: 'UK', name: 'UK' },
  { id: 'Mediterranean', name: 'Mediterranean' },
  { id: 'Indian Ocean', name: 'Indian Ocean' },
  { id: 'Caribbean', name: 'Caribbean' }
]

export const HJregions = [
  { id: 'NEMENA', name: 'Near and Middle East and North Africa' },
  { id: 'GRECHI', name: 'Greater China' },
  { id: 'AFRICA', name: 'Africa' },
  { id: 'USACAN', name: 'USA and Canada' },
  { id: 'INDOCE', name: 'Indian Ocean' },
  { id: 'CENAME', name: 'Central America' },
  { id: 'INDSUB', name: 'Indian Subcontinent' },
  { id: 'CARRIB', name: 'Caribbean' },
  { id: 'FAREAS', name: 'Far East' },
  { id: 'FAREAS2', name: 'Far East 2' }
]

export const SSbases = [
  { id: 'Raiatea', name: 'Raiatea' },
  { id: 'Agana', name: 'Agana' },
  { id: 'Nassau (Exumas)', name: 'Nassau (Exumas)' },
  { id: 'Procida', name: 'Procida' },
  { id: 'Dubrovnik', name: 'Dubrovnik' },
  { id: 'Portsmouth', name: 'Portsmouth' },
  { id: 'Portorosa', name: 'Portorosa' },
  { id: 'Zea (Athens)', name: 'Zea (Athens)' },
  { id: 'Grenada', name: 'Grenada' },
  { id: 'St Martin', name: 'St Martin' },
  { id: 'Phuket', name: 'Phuket' },
  { id: 'The Whitsundays', name: 'The Whitsundays' },
  { id: 'Corfu', name: 'Corfu' },
  { id: 'Mallorca', name: 'Mallorca' },
  { id: 'Seychelles', name: 'Seychelles' },
  { id: 'Vavau', name: 'Vavau' },
  { id: 'Belize', name: 'Belize' },
  { id: 'Antigua', name: 'Antigua' },
  { id: 'Sardinia', name: 'Sardinia' },
  { id: 'Vancouver', name: 'Vancouver' },
  { id: 'Tortola', name: 'Tortola' },
  { id: 'Lefkas', name: 'Lefkas' },
  { id: 'St Lucia', name: 'St Lucia' },
  { id: 'Abacos', name: 'Abacos' }
]

export const HJdests = [
  { id: 'China', name: 'China' },
  { id: 'Maldives', name: 'Maldives' },
  { id: 'Seychelles', name: 'Seychelles' },
  { id: 'Canada', name: 'Canada' },
  { id: 'Sri Lanka', name: 'Sri Lanka' },
  { id: 'United Arab Emirates', name: 'United Arab Emirates' },
  { id: 'Tanzania', name: 'Tanzania' },
  { id: 'Mexico', name: 'Mexico' },
  { id: 'Costa Rica', name: 'Costa Rica' },
  { id: 'Thailand2', name: 'Thailand2' },
  { id: 'Mauritius', name: 'Mauritius' },
  { id: 'Indonesia', name: 'Indonesia' },
  { id: 'Malaysia', name: 'Malaysia' },
  { id: 'Kenya', name: 'Kenya' },
  { id: 'Japan', name: 'Japan' },
  { id: 'Caribbean', name: 'Caribbean' },
  { id: 'Philippines', name: 'Philippines' },
  { id: 'U S A', name: 'U S A' },
  { id: 'India', name: 'India' },
  { id: 'Vietnam', name: 'Vietnam' },
  { id: 'Singapore', name: 'Singapore' },
  { id: 'Thailand', name: 'Thailand' },
  { id: 'South Africa', name: 'South Africa' }
]
