import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  UrlField
} from 'react-admin'

export const ProductList = props => (
  <List {...props}>
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <TextField source='brand' label='Brand' />
      <TextField source='market' label='Market' />
      <TextField source='commodity' label='Commodity' />
      <TextField source='country' label='Country' />
      <TextField source='region' label='Region' />
      <TextField source='name' label='Product Name' />
      <NumberField source='rank' label='Rank' />
      <DateField source='startwhen' label='Effective From' />
      <DateField source='endwhen' label='Effective To' />
      <TextField source='journey' label='Journey' />
      <UrlField source='url' label='Product URL' />
      <TextField source='product' label='Product' />
      <TextField source='hull' label='Hull Type' />
      <TextField source='skippered' label='Skippered' />
      <TextField source='discount' label='Discount' />
      <TextField source='producttypecode' label='Product Type' />
      <TextField source='productstylecode' label='Product Style' />
      <TextField source='partytypecode' label='Party Type' />
      <TextField source='partytypeflexible' label='Party Type Flexible' />
    </Datagrid>
  </List>
)

export default ProductList
