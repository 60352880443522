import React from 'react'
import {
  Show,
  Tab,
  BooleanField,
  TabbedShowLayout,
  NumberField,
  ArrayField,
  TextField,
  SelectField,
  ChipField,
  Labeled,
  SimpleShowLayout,
  Datagrid,
  TopToolbar,
  EditButton,
  ListButton
} from 'react-admin'
import {
  DestinationSelectionOptions,
  productTypes,
  partyTypes,
  productStyles,
  inMarketSpecialSelection,
  selectionMethods,
  days,
  daystype,
  brands,
  contactProperties,
  runFrequencies
} from './RuleSelects'
import RuleAside from './RuleAside'

const RuleTitle = ({ record }) => {
  return <span>Journey {record ? `${record.payload.journeyName}` : ''}</span>
}

const RuleShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
)

const RuleShow = (props) => {
  const HubSpotOverrides = function ({ record }) {
    const fields = []
    if (record.hasOwnProperty('payload') && record.payload.hasOwnProperty('pushunknowntohs')) {
      if (record.payload.pushunknowntohs) {
        fields.push(<Labeled label='Unknown product code'><TextField
          label='Unknown product code'
          record={record}
          source='payload.unknownproductcode'
                                                          />
                    </Labeled>)
        fields.push(<Labeled label='Unknown product URL'><TextField
          label='Unknown product URL' record={record}
          source='payload.unknownproducturl'
                                                         />
                    </Labeled>)
      }
    }
    return fields
  }
  const Duration = ({ record }) => (
    <div>
      <Labeled label='Number of days'>
        <SelectField label='Number of days' record={record} source='payload.days' choices={days} />
      </Labeled>
      <Labeled label='Type of days'>
        <SelectField label='Type of days' record={record} source='payload.daystype' choices={daystype} />
      </Labeled>
      <Labeled label='Number'>
        <NumberField label='Number' record={record} source='payload.number' />
      </Labeled>
    </div>
  )
  const InMarketAll = ({ record }) => (
    <div>
      <Labeled label='In market all, likelihood score >= '>
        <NumberField label='In market all, likelihood score >= ' record={record} source='payload.inmarketallthreshhold' />
      </Labeled>
    </div>
  )
  const DestinationField = function ({ record }) {
    if (record && record.hasOwnProperty('destselection')) {
      console.log(record)
      switch (record.destselection) {
        case 'dest2':
          return <Labeled label='Static destination'><ChipField
            label='Static destination' record={record}
            source='staticdest'
                                                     />
                 </Labeled>
        case 'dest4':
          return <Labeled label='Destination/base'><ChipField
            label='Destination/base' record={record}
            source='destnum'
                                                   />
                 </Labeled>
        case 'dest5':
          return <Labeled label='Linked destination'><ChipField
            label='Linked destination' record={record}
            source='linkeddest'
                                                     />
                 </Labeled>
        case 'dest6':
          return <Labeled label='Static region'><ChipField
            label='Static region' record={record}
            source='staticregion'
                                                />
                 </Labeled>
        case 'dest7':
          return <Labeled label='Region destination'><ChipField
            label='Region destination' record={record}
            source='regiondest'
                                                     />
                 </Labeled>
        default:
          return null
      }
    }
    return null
  }
  const InMarketSpecial = ({ record }) => (
    <div>
      <SelectField label='' source='payload.inmarketspecial' record={record} choices={inMarketSpecialSelection} />
      <InMarketSpecialFields record={record} />
    </div>
  )
  const InMarketSpecialFields = function ({ record }) {
    const fields = []

    if (record.payload.hasOwnProperty('inmarketspecial')) {
      if (record.payload.inmarketspecial.includes('inmarketrepeat')) {
        fields.push(<Labeled label='In market repeats, likelihood score >= '><NumberField
          key='inmarketrepeat' record={record}
          source='payload.inmarketrepeatthreshhold'
        />
                    </Labeled>)
      }
      if (record.payload.inmarketspecial.includes('inmarketprospects')) {
        fields.push(<Labeled label='In market prospects, likelihood score >= '><NumberField
          key='inmarketprospects' record={record}
          source='payload.inmarketprospectthreshhold'
        />
                    </Labeled>)
      }
    }
    return fields
  }
  const OverrideFields = function ({ record }) {
    const fields = []
    if (record && record.hasOwnProperty('overrides')) {
      if (record.overrides.includes('useproductstyle')) {
        fields.push(<Labeled label='Product style'><SelectField
          choices={productStyles} record={record} label='Product style'
          source='productstyle'
                                                   />
                    </Labeled>)
      }
      if (record.overrides.includes('usepartytype')) {
        fields.push(<Labeled label='Party type'><SelectField
          choices={partyTypes} record={record} label='Party type'
          source='partytype'
                                                />
                    </Labeled>)
      }
      if (record.overrides.includes('useproducttype')) {
        fields.push(<Labeled label='Product type'><SelectField
          choices={productTypes} record={record} label='Product type'
          source='producttype'
                                                  />
                    </Labeled>)
      }
    }

    return fields
  }
  const SelectMethodFields = ({ record }) => {
    const fields = []
    if (record.hasOwnProperty('payload') && record.payload.hasOwnProperty('selectMethod')) {
      if (record.payload.selectMethod === 'duration') {
        fields.push(<Duration record={record} />)
      }

      if (record.payload.selectMethod === 'inmarketall') {
        fields.push(<InMarketAll record={record} />)
      }

      if (record.payload.selectMethod === 'inmarketspecial') {
        fields.push(<InMarketSpecial record={record} />)
      }
    }
    return fields
  }

  return (
    <Show aside={<RuleAside />} actions={<RuleShowActions />} title={<RuleTitle />} {...props}>

      <TabbedShowLayout>
        <Tab label='Journey data'>
          <TextField label='Id' source='id' />
          <TextField source='payload.journeyName' label='Journey Name' />
          <SelectField label='Brand' source='payload.brand' choices={brands} />
          <SelectField
            label='Contact property' source='payload.contactProperty'
            choices={contactProperties}
          />
        </Tab>
        <Tab label='Contact selection'>
          <SelectField
            source='payload.selectMethod' choices={selectionMethods}
            label='Contact selection method'
          />
          <SelectMethodFields />
        </Tab>
        <Tab label='Run frequency'>
          <SelectField label='Run frequency' source='payload.runfreq' choices={runFrequencies} />
        </Tab>
        <Tab label='Rules'>
          <ArrayField label='Product matching rulels' source='payload.rules'>
            <Datagrid label='Product matching rulels'>
              <NumberField label='Products' source='products' />
              <NumberField label='Offset' source='offset' />
              <SelectField
                label='Destination selection' choices={DestinationSelectionOptions}
                source='destselection'
              />
              <DestinationField label='Selection properties' />
              <OverrideFields label='Overriden fields' />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label='Misc'>
          <BooleanField
            label='Use properties (style, type, party, hull, skippered, etc) from other campaigns (if available) to back-fill?'
            source='payload.backfill'
          />
          <BooleanField
            label='Push to Hubspot even if product is unknown?'
            source='payload.pushunknowntohs'
          />
          <BooleanField
            label='Allow regional match if no destination/base level match?'
            source='payload.regionalmatch'
          />
          <TextField label='Reenter journey' source='payload.reenterjourneydays' />
          <TextField
            label='Re-enter same destination/base journey'
            source='payload.reenterjourneydayssamedest'
          />
          <HubSpotOverrides label='Hubspot overrides' />
        </Tab>
      </TabbedShowLayout>

    </Show>
  )
}
// const Aside = ({ record }) => (
//
//     <div>
//         {record && (
//             <Paper elevation={2}>
//                 <SimpleShowLayout>
//                     <Typography><RuleTitle/></Typography>
//                     {/*<pre>{JSON.stringify(blend, 0, 2)}</pre>*/}
//                     <ApproveButton record={record}/>
//                 </SimpleShowLayout>
//             // </Paper>
//         )}
//     </div>
// );

export default RuleShow
