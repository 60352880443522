import RuleIcon from '@material-ui/icons/Collections'
import RuleList from './RuleList'
import RuleShow from './RuleShow'
import RuleEditForm from './RuleEditForm'
import RuleCreateForm from './RuleCreateForm'

export default {
  icon: RuleIcon,
  list: RuleList,
  show: RuleShow,
  edit: RuleEditForm,
  create: RuleCreateForm
}
